
import axios from 'axios';

/**
 * Autentica al usuario con las credenciales de Firmatech.
 * @param {string} user - El username.
 * @param {string} password - El password.
 * @returns {Promise<Object>} - El objeto de respuesta de la solicitud de autenticación.
 */
export async function authFirmatech(user, password) {
    const requestData = { user, password };

    const response = await axios.post(
        `/auth/login`,
        requestData
    );

    return response;
}

/**
 * Intercambia los tokens de acceso.
 * @returns {Promise<Object>} - El objeto de respuesta de la solicitud de intercambio de tokens.
 */
export async function exchangeTokens() {
    console.log("vamos a canjear un nuevo access token");

    const header = { Authorization: "Bearer " + sessionStorage.getItem("refresh_token") };
    const configuration = { headers: header };

    const response = await axios.post(
        `/auth/refresh-token`,
        null,
        configuration
    );

    return response;
}

/**
 * Intercambia los tokens de acceso.
 * @returns {Promise<Object>} - El objeto de respuesta de la solicitud de intercambio de tokens.
 */
export async function getTokens(code, redirect_url) {
    console.log("vamos a canjear un nuevo access token");

    const response = await axios.get(
        `/auth/token/${code}/${encodeURIComponent(redirect_url)}`
    );

    return response;
}

/**
 * Mata los tokens.
 * @returns {Promise<Object>} - El objeto de respuesta de la solicitud de intercambio de tokens.
 */
export async function logoutKeyCloak() {
    console.log("vamos a cerrar la sesion");

    try {
        const header = { Authorization: "Bearer " + sessionStorage.getItem("access_token") };
        const configuration = { headers: header };

        const requestData = { refresh_token: sessionStorage.getItem("refresh_token") };

        const response = await axios.post('/auth/logout', requestData, configuration);

        console.log('Logout successful:', response.data);
    } catch (error) {
        console.error('Error during logout:', error);
    }
}