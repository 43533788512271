<template>
    <v-container fluid class="animate__animated animate__fadeIn">
        <v-row>
            <v-col cols="12">
                <v-card flat class="card-img-home">
                    <v-img :src="require('@/assets/img/banner.svg')" alt="Bienvenido a Firmatech Back Office"
                        class="banner"></v-img>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="pa-4 mb-4 mh-120" flat>
                    <v-card-title>
                        <span class="headline">DigiYO Admin</span>
                    </v-card-title>
                    <v-card-text>
                        <p>DigiYO es una API de <strong>inteligencia artificial</strong> basada en técnicas de
                            <strong>Computer Vision</strong>s para validar imágenes de personas y sus documentos
                            de identidad. Esta herramienta es sumamente flexible y se adapta a las necesidades
                            de cada cliente de manera personalizada.
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
                <v-card class="pa-4 mb-4 mh-180" flat>
                    <v-card-title>
                        <span class="headline">Servicio Básico</span>
                        <span class="headline">Párametros de Entrada Requeridos</span>
                    </v-card-title>
                    <v-card-text>
                        <ul>
                            <li>Foto de CI Frontal</li>
                            <li>Foto de CI Trasera</li>
                            <li>Selfie con la CI en mano</li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="pa-4 mb-4 mh-180" flat>
                    <v-card-title>
                        <span class="headline">Servicio Completo</span>
                        <span class="headline">Párametros de Entrada Requeridos</span>
                    </v-card-title>
                    <v-card-text>
                        <ul>
                            <li>Foto de CI Frontal</li>
                            <li>Foto de CI Trasera</li>
                            <li>Selfie con la CI en mano</li>
                            <li>Video de Prueba de Vida</li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="pa-4 mh-180" flat>
                    <v-card-title>
                        <span class="headline">Más opciones</span>
                    </v-card-title>
                    <v-card-text>
                        <p>Si deseas opciones más personalizadas, como validaciones adicionales, no dudes en
                            <a href="mailto:info@roshka.com">contactarnos.</a></p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
.banner {
    width: 100%;
    object-fit: cover;
    transform: scale(1.01);
}

.card-img-home {
    border-radius: 10px !important;
}

.mh-120 {
    min-height: 150px;
}

.mh-180 {
    min-height: 180px;
}
</style>
