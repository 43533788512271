import axios from "axios";
import { baseUrl } from "@/config/globalVariables";
import { exchangeTokens } from '@/api/auth';
import eventBus from '@/config/eventBus';

export async function setBaseUrl() {
    axios.defaults.baseURL = baseUrl;
}

export async function setInterceptors() {
    axios.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;
            const excludedUrls = ['/auth/refresh-token', '/auth/login'];

            if (error.response?.status === 401 && !originalRequest._retry && !excludedUrls.includes(originalRequest.url)) {
                originalRequest._retry = true;
                if (sessionStorage.getItem("refresh_token")) {
                    try {
                        return await handleRefreshToken(originalRequest);
                    } catch (error) {
                        console.error('Refresh token exchange failed:', error);
                        sessionStorage.removeItem("access_token");
                        sessionStorage.removeItem("refresh_token");

                        eventBus.emit('logout');
                        return Promise.reject(error);
                    }
                }
            }
            return Promise.reject(error);
        }
    );
}

async function handleRefreshToken(originalRequest) {
    const response = await exchangeTokens();

    if (sessionStorage.getItem("access_token")) {
        sessionStorage.removeItem("access_token");
    }

    if (sessionStorage.getItem("refresh_token")) {
        sessionStorage.removeItem("refresh_token");
    }

    sessionStorage.setItem("access_token", response.data.access_token);
    sessionStorage.setItem("refresh_token", response.data.refresh_token);

    originalRequest.headers['Authorization'] = "Bearer " + sessionStorage.getItem("access_token");
    return axios(originalRequest);
}