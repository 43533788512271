<template>
    <Spinner v-if="showSpinner" :message="spinnerMessage" class="animate__animated animate__fadeIn" />
</template>

<script>
import DialogOk from '@/components/dialog/DialogOk';
import Spinner from '@/components/Spinner.vue';

export default {
    components: {
        DialogOk,
        Spinner
    },
    data() {
        return {
            username: '',
            password: '',
            authLoading: false,
            accessToken: null,
            refreshToken: null,
            dialog: false,
            message: '',
            title: '',
            rules: {
                required: value => !!value || 'Este campo es requerido.',
            },
            showSpinner: true,
            spinnerMessage: 'Aguarde por favor, estamos estamos preparando el servicio de autenticación...',
        };
    },
    methods: {
        showMessage(message, title = "Info") {
            this.title = title;
            this.message = message;
            this.dialog = true;
        },
    }
};
</script>

<style scoped>
.min-vh-100 {
    min-height: 100vh;
}

.max-w-400 {
    max-width: 400px;
}
</style>
