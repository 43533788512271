<template>
    <div id="loadingDiv" class="loading-overlay" v-if="isMounted">
        <div class="fingerprint-spinner">
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
        </div>
        <p class="text-center font-13">{{ message }}</p>
        <p :key="statusKey" class="font-13 text-center mt-3 animate__animated " :class="animationClass">{{ messageStatus
            }}</p>
    </div>
</template>

<script>
export default {
    name: "Spinner",
    props: {
        message: {
            type: String,
            default: '',
            required: true
        },
        messageStatusList: {
            type: Array,
            default: [],
            required: false
        },
        showStatus: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            animationClass: "animate__fadeInUp",
            messageStatus: null,
            internalShow: false,
            statusKey: 0, // obliga a redibujar el componente cuando cambia el key
            isMounted: false // Estado para cortar el for cuando el componente se desmonta antes de terminar
        }
    },
    async mounted() {
        console.log(this.messageStatusList.length);
        this.isMounted = true;
        for (let counter = 0; counter < this.messageStatusList.length; counter++) {
            if (!this.isMounted) return;
            this.messageStatus = this.messageStatusList[counter];
            this.statusKey = this.statusKey + 1;
            console.log("mostrando mensaje: ", this.messageStatus);
            await this.delay(2000);
            this.animationClass = this.animationClass === "animate__fadeInUp" ? "animate__fadeOutUp" : "animate__fadeInUp";
            await this.$nextTick();

            await this.delay(2000);
            this.animationClass = this.animationClass === "animate__fadeInUp" ? "animate__fadeOutUp" : "animate__fadeInUp";
            this.statusKey = this.statusKey + 1;
            await this.$nextTick();
        }
    },
    beforeUnmount() {
        this.isMounted = false;  // Establece como falso cuando el componente va a desmontarse
    },
    methods: {
        delay(time) {
            return new Promise(resolve => setTimeout(resolve, time));
        },
    },
}
</script>

<style scoped>
#loadingDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    justify-content: center;
    display: flex;
    align-items: center;
    z-index: 9999;
    pointer-events: all;
}

.fingerprint-spinner,
.fingerprint-spinner * {
    box-sizing: border-box;
}

.fingerprint-spinner {
    height: 64px;
    width: 64px;
    padding: 2px;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
}

.fingerprint-spinner .spinner-ring {
    position: absolute;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: var(--primary-color);
    animation: fingerprint-spinner-animation 1500ms cubic-bezier(0.680, -0.750, 0.265, 1.750) infinite forwards;
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.fingerprint-spinner .spinner-ring:nth-child(1) {
    height: calc(60px / 9 + 0 * 60px / 9);
    width: calc(60px / 9 + 0 * 60px / 9);
    animation-delay: calc(50ms * 1);
}

.fingerprint-spinner .spinner-ring:nth-child(2) {
    height: calc(60px / 9 + 1 * 60px / 9);
    width: calc(60px / 9 + 1 * 60px / 9);
    animation-delay: calc(50ms * 2);
}

.fingerprint-spinner .spinner-ring:nth-child(3) {
    height: calc(60px / 9 + 2 * 60px / 9);
    width: calc(60px / 9 + 2 * 60px / 9);
    animation-delay: calc(50ms * 3);
}

.fingerprint-spinner .spinner-ring:nth-child(4) {
    height: calc(60px / 9 + 3 * 60px / 9);
    width: calc(60px / 9 + 3 * 60px / 9);
    animation-delay: calc(50ms * 4);
}

.fingerprint-spinner .spinner-ring:nth-child(5) {
    height: calc(60px / 9 + 4 * 60px / 9);
    width: calc(60px / 9 + 4 * 60px / 9);
    animation-delay: calc(50ms * 5);
}

.fingerprint-spinner .spinner-ring:nth-child(6) {
    height: calc(60px / 9 + 5 * 60px / 9);
    width: calc(60px / 9 + 5 * 60px / 9);
    animation-delay: calc(50ms * 6);
}

.fingerprint-spinner .spinner-ring:nth-child(7) {
    height: calc(60px / 9 + 6 * 60px / 9);
    width: calc(60px / 9 + 6 * 60px / 9);
    animation-delay: calc(50ms * 7);
}

.fingerprint-spinner .spinner-ring:nth-child(8) {
    height: calc(60px / 9 + 7 * 60px / 9);
    width: calc(60px / 9 + 7 * 60px / 9);
    animation-delay: calc(50ms * 8);
}

.fingerprint-spinner .spinner-ring:nth-child(9) {
    height: calc(60px / 9 + 8 * 60px / 9);
    width: calc(60px / 9 + 8 * 60px / 9);
    animation-delay: calc(50ms * 9);
}

@keyframes fingerprint-spinner-animation {
    100% {
        transform: rotate(360deg);
    }
}

.loading-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    pointer-events: all;
    /* Habilita la captura de eventos */
}
</style>
