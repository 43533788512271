console.log("ambiente", process.env.NODE_ENV);

const baseUrl = process.env.NODE_ENV === 'development'
    ? `https://${window.location.hostname}:5123/api`
    : process.env.VUE_APP_API_BASE_URL;

const localBaseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/`;

const keyCloakCodeUrl = `${process.env.VUE_APP_KEYCLOAK_BASE_URL}/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/auth?client_id=${process.env.VUE_APP_KEYCLOAK_CLIENT_ID}&response_type=code&redirect_uri=${localBaseUrl}`;
const keyCloakAccountUrl = `${process.env.VUE_APP_KEYCLOAK_BASE_URL}/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/account`;

const digiyoBaseUrl = process.env.VUE_APP_DIGIYO_BASE_URL;

export {
    baseUrl,
    digiyoBaseUrl,
    keyCloakCodeUrl,
    localBaseUrl,
    keyCloakAccountUrl
};
