import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { es } from 'vuetify/locale'; 
import '@/assets/css/colors.css'; // Importar colores personalizados

// documentacion => https://vuetifyjs.com/en/components
const myCustomDarkTheme = {
    dark: true,
    colors: {
        background: 'rgb(19, 27, 49)',
        surface: 'rgb(19, 27, 49)',
        primary: 'rgb(2, 132, 199)',
        'primary-darken-1': '#0369a1',
        secondary: 'rgb(185, 226, 140)',
        'secondary-darken-1': '#7e22ce',
        error: 'rgb(254, 95, 85)',
        info: 'rgb(2, 70, 167)',
        success: 'rgb(62, 185, 127)',
        warning: 'rgb(243, 167, 56)',
        'on-background': '#e5e7eb',
        'on-surface': '#e5e7eb',
        'on-primary': '#e5e7eb',
        'on-secondary': '#e5e7eb',
        'on-error': '#e5e7eb',
        'on-info': '#e5e7eb',
        'on-success': '#e5e7eb',
        'on-warning': '#e5e7eb',
    },
};

export default createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
    },
    theme: {
        defaultTheme: 'myCustomDarkTheme',
        themes: {
            myCustomDarkTheme,
        },
    },
    locale: {
        locale: 'es',
        messages: { es },
    },
});
