<template>
    <v-app-bar app flat>
        <v-app-bar-nav-icon @click="$emit('toggleRail')"></v-app-bar-nav-icon>
        <router-link class="mt-3" :to="{ name: 'home' }"><img alt="logo" src="@/assets/img/logo-dark.svg"
                height="35"></router-link>
    </v-app-bar>
</template>

<script>
export default {
    name: 'AppBar',
};
</script>

<style scoped>
.v-toolbar {
    border-bottom: 1px solid rgba(var(--background-tailwind-dark-color), var(--border-toolbar-opacity)) !important;
}
</style>