<template>
    <div class="text-center pa-4">
        <v-dialog v-model="internalShow" max-width="450">
            <v-card prepend-icon="mdi-information-outline" color="surface" dark :title="title">
                <template v-slot:text>
                    <div v-html="sanitizedMessage"></div>
                </template>
                <template v-slot:actions>
                    <v-btn @click="close" color="primary" variant="tonal">
                        Aceptar
                    </v-btn>
                </template>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DOMPurify from 'dompurify';

export default {
    name: "DialogOk",
    props: {
        message: {
            type: String,
            default: '',
            required: true
        },
        title: {
            type: String,
            default: '',
            required: true
        },
        show: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            internalShow: this.show
        };
    },
    watch: {
        show(val) {
            this.internalShow = val;
        },
        internalShow(val) {
            this.$emit('update:show', val);
        }
    },
    methods: {
        close() {
            this.internalShow = false;
        }
    },
    computed: {
        sanitizedMessage() {
            return DOMPurify.sanitize(this.message);
        }
    }
}
</script>
